import * as React from 'react';
import cx from 'classnames';
import { Paging, Results } from '@elastic/react-search-ui';
import styled from 'styled-components';
import '@elastic/react-search-ui-views/lib/styles/styles.css';

import {
  AutocompleteResults, ResultFields, Result, SearchBar, SearchProvider,
} from '../components/search/resource';
import JoinUsFormWrapper from '../components/form/presentational/JoinUsFormWrapper';
import Layout from '../components/core/layout';
import SEO from '../components/core/seo';

// Style
import { above, colors, space } from '../style/design-system';

type ResourcesPageProps = {
  className?: string
};

const ResourcesPageDefaultProps = {
  className: '',
};

const ResourcesPage = ({ className }: ResourcesPageProps) => (
  <Layout className={className}>
    <SEO title="Resources" />
    <div className="container my-11">
      <div className="row">
        <div className="col-12 text-center">
          <h1>Find resources that can make life easier.</h1>
          <p>When resources are within reach, communities thrive.</p>
        </div>
      </div>
    </div>
    <SearchProvider>
      <div className="container my-11">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
            <SearchBar
              autocompleteResults={false}
              autocompleteView={(
                { autocompletedResults, value }:
                { autocompletedResults: ResultFields[], value: string },
              ) => (
                <AutocompleteResults autocompletedResults={autocompletedResults} value={value} />
              )} />
            <p className="mt-4 mb-0 text-center">
              <small>Search by zip code to find local&nbsp;resources.</small>
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <Results
          className="row px-0"
          resultView={(
            (props: { className?: string, result: ResultFields }) => {
              if (props.result.resource_name.raw) {
                return (
                  <div className={cx('col-md-4', 'my-6')}>
                    <Result className={props.className} result={props.result} />
                  </div>
                );
              }
              return null;
            })} />
        <div className="text-center">
          <Paging />
        </div>
      </div>
    </SearchProvider>
    <JoinUsFormWrapper hideCourses />
  </Layout>
);

ResourcesPage.defaultProps = ResourcesPageDefaultProps;

export default styled(ResourcesPage)`

  .rc-pagination {
    display: inline-block;
    font-size: 1.2rem;
    margin: 0 auto;
  }

  .sui-search-box {
    border: none;
    .sui-search-box__wrapper, .sui-search-box__submit {
      border: 1px solid ${colors.umaBlack};
    }
    .sui-search-box__wrapper {
      .sui-search-box__autocomplete-container, .sui-search-box__results-list {
        background: #fff;
        ul {
          list-style: none;
          padding-left: ${space.f};
          li {
            text-align: left;
            margin-bottom: ${space.b};
            text-indent: -2.125rem;
            margin-left: 1.125rem;
            &::before {
              content: ">";
              margin: 0.5rem 0.5rem 0px 1rem;
            }
          }
        }
      }
    }
    .sui-search-box__submit {
      align-self: flex-start;
      height: 3.875rem;
    }
  }
`;
